import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

// Note: This map controller can probably be generalized into a generic "coverage"
// map, that allows showing areas of zip codes. We would need to be able to pass
// any setting as a value.
//
// Connects to data-controller="fundraising-map"
export default class extends Controller {
  static values = {
    // Array of objects of this type
    // {
    //   name: String,
    //   zip_codes: Array<string>,
    //   popup: string (html),
    //   marker: {
    //     lat: float,
    //     lng: float,
    //     image_url: string (if in assets),
    //     icon: string (html) (if no image_url)
    //   }
    // }
    areas: Array,
  };

  connect() {
    const map = this.#initMap();

    this.#showAreas(map);

    this.resizeObserver = new ResizeObserver(() => {
      map.resize();
    });
    this.resizeObserver.observe(this.element);
  }

  disconnect() {
    this.resizeObserver.disconnect();
  }

  #initMap() {
    mapboxgl.accessToken = process.env.MAPBOX_API_KEY;

    const map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/light-v11",
      cooperativeGestures: true,
    });

    const markers = this.areasValue.map(({ marker }) => marker);

    const bounds = new mapboxgl.LngLatBounds();
    if (markers.length) {
      markers.forEach((marker) => bounds.extend([marker.lng, marker.lat]));
    } else {
      bounds.extend([6.7759719, 46.5704405]); // Coworking du Jorat, le centre du monde
    }
    map.fitBounds(bounds, { maxZoom: 8 });

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    return map;
  }

  #showAreas(map) {
    map.on("load", () => {
      map.addSource("ch-plz", {
        type: "vector",
        url: "mapbox://tanguy-rdf.6qo8otpa",
        promoteId: "mapbox_id",
      });

      this.areasValue.forEach(({ name, zip_codes, popup, marker }, index) => {
        map.addLayer({
          id: name,
          type: "fill",
          source: "ch-plz",
          "source-layer": "chplzfull",
          filter: ["in", "plz"].concat(zip_codes),
          paint: {
            "fill-color": "#46DA9C",
            "fill-opacity": 0.8,
          },
        });

        this.#addMarker(map, marker);

        map.on("click", () => {
          map.setPaintProperty(name, "fill-color", "#46DA9C");
        });

        map.on("click", name, ({ lngLat }) => {
          map.setPaintProperty(name, "fill-color", "#339E71");

          if (popup) {
            new mapboxgl.Popup().setLngLat(lngLat).setHTML(popup).addTo(map);
          }
        });
      });
    });
  }

  #addMarker(map, marker) {
    if (!marker) return;

    const element = document.createElement("div");

    if (marker.icon) {
      // If given `icon` html, make sure it fits well in a 30px x 30px
      element.innerHTML = marker.icon;
      element.style.width = "30px";
      element.style.height = "30px";
    } else if (marker.image_url) {
      element.style.backgroundImage = `url('${marker.image_url}')`;
      element.style.backgroundSize = "contain";
      element.style.width = "27px";
      element.style.height = "30px";
    }
    const mapMarker = new mapboxgl.Marker(element).setLngLat([
      marker.lng,
      marker.lat,
    ]);

    mapMarker.addTo(map);
  }
}
