import Noty from "noty";

export function startAlert(type, text) {
  new Noty({
    type: type,
    text: text,
    theme: "metroui",
    layout: "bottomRight",
    timeout: 3000,
  }).show();
}
