import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["display", "radius", "title"];

  static values = {
    showText: String,
    hideText: String,
  };

  slide() {
    this.displayTarget.classList.toggle("hide-carousel");
    this.radiusTarget.classList.toggle("hide-radius-left");
    this.#toggleText();
  }

  #toggleText() {
    if (
      this.titleTarget.innerText.toUpperCase() ===
      this.showTextValue.toUpperCase()
    ) {
      this.titleTarget.innerText = this.hideTextValue;
    } else {
      this.titleTarget.innerText = this.showTextValue;
    }
  }
}
