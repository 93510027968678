import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

UNCANCELLABLE_OPTIONS = { backdrop: "static", keyboard: false };

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    openOnLoad: Boolean,
    uncancellable: Boolean,
  };

  connect() {
    const options = this.uncancellableValue ? UNCANCELLABLE_OPTIONS : {};

    this.modal = new Modal(this.element, options);

    if (this.openOnLoadValue) this.open();
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show();
      this.#autofocus();
    }
  }

  close(event) {
    const isSubmitEnd = event.type === "turbo:submit-end";
    const submitSuccess = isSubmitEnd && event.detail.success;

    if (submitSuccess || !isSubmitEnd) this.modal.hide();
  }

  #autofocus() {
    const focusable = this.element.querySelector("[autofocus]");

    // Note: the timeout is due to bootstrap executing a `focus()`
    // on its own, which happens after load is done. Can't figure
    // how to listen to bootstrap events, nothing seems to work.
    // resorting to delay by 500ms as a hackish solution
    if (focusable)
      setTimeout(() => {
        focusable.focus();
        focusable.select();
      }, 500);
  }
}
