import { Controller } from "@hotwired/stimulus";
import { startAlert } from "../plugins/noty";

// Connects to data-controller="notification"
export default class extends Controller {
  static values = {
    text: String,
    type: String,
  };

  connect() {
    const text = this.textValue || this.element.innerText;
    const type = this.typeValue || "error";

    startAlert(type, text);
  }
}
