export const showCrisp = () => {
  document
    .querySelectorAll(".crisp-client")
    .forEach((el) => (el.style.display = "initial"));
};

export const hideCrisp = () => {
  document
    .querySelectorAll(".crisp-client")
    .forEach((el) => (el.style.display = "none"));
};

export const showAxeptio = () => {
  document
    .querySelectorAll("#axeptio_overlay")
    .forEach((el) => (el.style.display = "initial"));
};

export const hideAxeptio = () => {
  document
    .querySelectorAll("#axeptio_overlay")
    .forEach((el) => (el.style.display = "none"));
};
