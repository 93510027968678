import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="basket-popup"
export default class extends Controller {
  static targets = ["frame"];

  connect() {
    this.element.querySelector("[tabindex]")?.focus();
  }

  close() {
    this.frameTarget.textContent = "";
  }
}
