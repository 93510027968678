import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import { showOverlay, hideOverlay } from "../plugins/overlay";
import { startAlert } from "../plugins/noty";

// Connects to data-controller="datatrans"
export default class extends Controller {
  connect() {
    this.element.onsubmit = (event) => {
      event.preventDefault();

      this.#init();
    };
  }

  async #init() {
    showOverlay();

    const { action: url } = this.element;
    const method =
      this.element.querySelector('input[name="_method"]')?.value ||
      this.element.method;

    const body = JSON.stringify(Object.fromEntries(new FormData(this.element)));

    const request = new FetchRequest(method, url, {
      body,
      responseKind: "json",
    });
    const response = await request.perform();

    try {
      const payload = await response.json;

      if (response.ok) {
        this.#callback(payload);
      } else {
        hideOverlay();
        startAlert("error", payload.error);
        this.#enableButton();
      }
    } catch (e) {
      hideOverlay();
      startAlert(
        "error",
        "Diantre, Robin des fermes est dans les choux, une erreur de serveur empêche tristeusement le site de fonctionner. Reviens-plus tard ou actualise la page.",
      );
      this.#enableButton();
    }
  }

  #callback(payload) {
    const { transaction_id: transactionId, redirect_to } = payload;

    if (redirect_to) {
      window.location.assign(redirect_to);
      return;
    } else if (transactionId) {
      this.#openModal(transactionId);
    }
  }

  #openModal(transactionId) {
    window.Datatrans.startPayment({
      transactionId,
      loaded: () => {
        hideOverlay();
        this.#enableButton();
      },
    });
  }

  #enableButton() {
    this.element
      .querySelectorAll('[type="submit"]')
      .forEach((el) => (el.disabled = false));
  }
}
