const OVERLAY_CONFIG = {
  id: "25256761-40f9-4811-bc34-e417a290cfe3",
  threshold: 24,
  ratio: 5,
  transitionDuration: 300,
};

const getSpinnerHeight = (element) => {
  const { ratio, threshold } = OVERLAY_CONFIG;

  const elHeight = element.getBoundingClientRect().height;

  const refHeight = Math.min(
    window.innerHeight,
    element.getBoundingClientRect().height,
  );

  const idealHeight = refHeight / ratio;
  const underThresholdHeight = Math.min(threshold, 0.95 * refHeight);

  if (idealHeight > threshold) return idealHeight;
  return underThresholdHeight;
};

export const showOverlay = (element = document.body) => {
  const overlayElement = document.createElement("div");

  const { id: overlayId, transitionDuration } = OVERLAY_CONFIG;

  overlayElement.dataset.overlayId = overlayId;
  overlayElement.style.boxSizing = "border-box";
  overlayElement.style.position =
    element === document.body ? "fixed" : "absolute";
  overlayElement.style.display = "flex";
  overlayElement.style.flexFlow = "column";
  overlayElement.style.alignItems = "center";
  overlayElement.style.justifyContent = "space-around";
  overlayElement.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
  overlayElement.style.top = "0";
  overlayElement.style.left = "0";
  overlayElement.style.width = "100%";
  overlayElement.style.height = "100%";
  overlayElement.style.zIndex = "2147483647";
  overlayElement.style.opacity = "1";
  overlayElement.style.transition = `all ${transitionDuration / 1000}s ease`;

  const spinner = document.createElement("i");
  spinner.className = "fa fa-spinner fa-spin";
  spinner.style.color = "var(--primary)";
  spinner.style.opacity = "0.8";
  spinner.style.fontSize = `${getSpinnerHeight(element)}px`;

  overlayElement.append(spinner);
  element.append(overlayElement);
};

export const hideOverlay = (element = document.body) => {
  const { id: overlayId, transitionDuration } = OVERLAY_CONFIG;

  const overlayElement = element.querySelector(
    `[data-overlay-id="${overlayId}"]`,
  );
  if (overlayElement) {
    overlayElement.style.opacity = "0";
    setTimeout(() => overlayElement.remove(), transitionDuration);
  }
};
