import { Controller } from "@hotwired/stimulus";
import { Carousel } from "bootstrap";

// Connects to data-controller="bootstrap-carousel"
export default class extends Controller {
  connect() {
    this.carousel = new Carousel(this.element, {
      ride: "carousel",
      interval: 7000,
    });
    this.carousel.cycle();
  }

  disconnect() {
    this.carousel.dispose();
  }
}
