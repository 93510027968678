import { Controller } from "@hotwired/stimulus";
import { showOverlay, hideOverlay } from "../plugins/overlay";

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = [
    // Both total and qty hold the same value (the counter value)
    // but they have different purposes, so we need to assign both.
    "total",
    "qty",
  ];

  increment() {
    const total = parseInt(this.totalTarget.textContent);

    this.totalTarget.textContent = total + 1;
    this.qtyTarget.value = total + 1;
  }

  decrement() {
    const total = parseInt(this.totalTarget.textContent);

    if (total > 1) {
      this.totalTarget.textContent = total - 1;
      this.qtyTarget.value = total - 1;
    }
  }

  showOverlay(event) {
    showOverlay(event.submitter);
  }

  hideOverlay(event) {
    hideOverlay(event.target);
  }
}
