import { Controller } from "@hotwired/stimulus";
import { tns } from "tiny-slider/src/tiny-slider";

const WITH_ARROWS_OPTIONS = {
  loop: false,
  gutter: 10,
  nav: true,
  controls: true,
  autoplayButtonOutput: false,
  autoWidth: true,
  touchDrag: false,
  autoplay: false,
  controlsText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
};

const DEFAULT_OPTIONS = {
  nav: false,
  controls: false,
  autoplayButtonOutput: false,
  mouseDrag: true,
  responsive: {
    0: {
      items: 1,
      edgePadding: 0,
      autoplay: false,
    },
    900: {
      edgePadding: 190,
      loop: true,
      gutter: 10,
      items: 1,
      autoplay: true,
      autoplayTimeout: 7000,
      autoplayHoverPause: false,
    },
    1600: {
      edgePadding: 250,
      loop: true,
      gutter: 10,
      items: 1,
      autoplay: true,
      autoplayTimeout: 7000,
      autoplayHoverPause: false,
    },
  },
};

// Connects to data-controller="carousel"
export default class extends Controller {
  static values = {
    withArrows: Boolean,
  };
  static targets = ["container"];

  connect() {
    const opts = this.withArrowsValue ? WITH_ARROWS_OPTIONS : DEFAULT_OPTIONS;

    this.slider = tns({
      container: this.containerTarget,
      ...opts,
    });
  }

  disconnect() {
    this.slider.destroy();
  }
}
