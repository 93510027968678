import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

// Connects to data-controller="farms-map"
export default class extends Controller {
  static values = {
    // Array of objects of this type
    // {
    //   lat: float,
    //   lng: float,
    //   image_url: string (image from assets),
    //   popup: string (html, info popup)
    // }
    burrowMarkers: Array,
    nearbyMarkers: Array,
    farMarkers: Array,
  };

  connect() {
    const map = this.#initMap();

    this.#addMarkers(map, this.burrowMarkersValue);
    this.#addMarkers(map, this.nearbyMarkersValue);
    this.#addMarkers(map, this.farMarkersValue);

    if (this.nearbyMarkersValue.length === 0) {
      this.#fitBounts(map, this.farMarkersValue);
    } else if (
      this.farMarkersValue.length === 0 &&
      this.burrowMarkersValue.length !== 0
    ) {
      this.#fitBounts(map, this.burrowMarkersValue);
    } else {
      this.#fitBounts(map, this.nearbyMarkersValue);
    }
  }

  #initMap() {
    mapboxgl.accessToken = process.env.MAPBOX_API_KEY;

    const map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/streets-v11",
      cooperativeGestures: true,
    });

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    return map;
  }

  #addMarkers(map, markers) {
    markers.forEach((marker) => {
      const popup = new mapboxgl.Popup().setHTML(marker.popup);

      const element = document.createElement("div");
      element.className = "marker";
      element.style.backgroundImage = `url('${marker.image_url}')`;
      element.style.backgroundSize = "contain";
      element.style.width = "27px";
      element.style.height = "30px";

      let mapMarker = new mapboxgl.Marker(element).setLngLat([
        marker.lng,
        marker.lat,
      ]);

      const markerElement = mapMarker.getElement();

      // Open popup when cursor goes on marker
      markerElement.addEventListener("mouseenter", () => {
        popup.addTo(map);

        const popupEl = popup.getElement();

        if (popupEl) {
          popupEl.addEventListener("mouseleave", (event) => {
            const target = event.explicitOriginalTarget;
            if (target?.parentElement !== markerElement) {
              popup.getElement().classList.toggle("removed");
              setTimeout(() => popup.remove(), 300);
            }
          });
        }
      });

      // When mouse leaves marker, close popup
      markerElement.addEventListener("mouseleave", (event) => {
        const target = event.explicitOriginalTarget;
        if (target?.parentElement !== popup.getElement()) {
          popup.getElement().classList.toggle("removed");
          setTimeout(() => popup.remove(), 300);
        }
      });

      // Build all popups
      mapMarker.setPopup(popup);

      // Set all markers
      mapMarker.addTo(map);
    });
  }

  #fitBounts(map, markers) {
    const bounds = new mapboxgl.LngLatBounds();

    if (markers.length === 0) {
      bounds.extend([8.227512, 46.818188]);
      map.fitBounds(bounds, { maxZoom: 8 });
    } else {
      markers.forEach((marker) => bounds.extend([marker.lng, marker.lat]));
      map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
    }
  }
}
