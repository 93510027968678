import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["icon", "collapsable"];

  toggle() {
    this.collapser ||= new Collapse(this.collapsableTarget);
    this.collapser.toggle();

    this.iconTarget.classList.toggle("fa-chevron-down");
    this.iconTarget.classList.toggle("fa-chevron-up");
  }
}
