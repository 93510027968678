import box from "custombox";

const modalObject = {
  content: {
    speedIn: 240,
    speedOut: 240,
    effect: "rotatedown",
    target: ".minimum_price_not_reached_modal",
    positionX: "center",
    positionY: "center",
    id: "minimumPriceNotReachedModal",
  },
};

export const openPriceNotReached = async () => {
  const customBoxModal = new box.modal(modalObject);
  await customBoxModal.open();
};

export const closePriceNotReached = () => {
  box.modal.close("minimumPriceNotReachedModal");
};
