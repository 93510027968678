import { Controller } from "@hotwired/stimulus";
import { openBasket, closeBasket } from "../plugins/basketOpener";
import { showOverlay, hideOverlay } from "../plugins/overlay";
import {
  openPriceNotReached,
  closePriceNotReached,
} from "../plugins/priceNotReached";

// Connects to data-controller="basket"
export default class extends Controller {
  static targets = ["modal"];

  async open() {
    await openBasket();
    this.modalTarget.querySelector("[tabindex]")?.focus();
  }

  close() {
    closeBasket();
  }

  showOverlay() {
    showOverlay(this.modalTarget);
  }

  hideOverlay() {
    hideOverlay(this.modalTarget);
  }

  async openWithOverlay() {
    await this.open();
    this.showOverlay();
  }

  openPriceNotReached() {
    openPriceNotReached();
  }

  closePriceNotReached() {
    closePriceNotReached();
  }
}
