export const showToggleButton = (inputTag, { showText, hideText }) => {
  const toggleIcon = document.createElement("span");
  toggleIcon.className = "fa fa-eye toggle-icon";
  toggleIcon.title = "Montrer le mot de passe";
  // Each input can have different margins/padding
  const inputTagStyle = getComputedStyle(inputTag);
  // - 0.5 rem == half the icon's height (via font-size)
  toggleIcon.style = `bottom: calc(0.5 * ${inputTagStyle.height} - 0.5rem)`;
  toggleIcon.dataset.role = "password-toggle";

  toggleIcon.onclick = (e) => {
    if (inputTag.type === "password") {
      inputTag.type = "text";
      toggleIcon.classList.remove("fa-eye");
      toggleIcon.classList.add("fa-eye-slash");
      toggleIcon.title = hideText;
    } else if (inputTag.type === "text") {
      inputTag.type = "password";
      toggleIcon.classList.remove("fa-eye-slash");
      toggleIcon.classList.add("fa-eye");
      toggleIcon.title = showText;
    }
  };

  inputTag.parentNode.appendChild(toggleIcon);
};
