import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-tab"
export default class extends Controller {
  static values = { subscriptionsInvalid: Boolean, userInvalid: Boolean };

  initialize() {
    const activeTab = location.hash.length
      ? this.element.querySelector(`a[href='${location.hash}']`)
      : null;

    if (activeTab) {
      activeTab.click();
    } else if (this.subscriptionsInvalidValue) {
      this.goToSubscriptions();
    } else if (this.userInvalidValue) {
      this.goToUser();
    }
  }

  goToUser() {
    this.element.querySelector(".nav #user-tab").click();
  }

  goToSubscriptions() {
    this.element.querySelector(".nav #sub-tab").click();
  }
}
