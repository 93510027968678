import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

DEFAULT_TRIGGER = "hover focus";

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    trigger: String,
  };

  connect() {
    this.tooltip = new Tooltip(this.element, {
      trigger: this.triggerValue || DEFAULT_TRIGGER,
    });
  }

  disconnect() {
    // glitch: looks like if a tooltip is open at the moment a frame is refreshed,
    // the tooltip div will remain in the DOM, and jump to the top-right corner.
    // removing every tooltip on the page on disconnect (bit brutal)
    document.querySelectorAll(".tooltip").forEach((el) => el.remove());
    this.tooltip.dispose();
  }
}
